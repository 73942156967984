import auth from "./auth/reducer";
import responseHandler from "./responseHandler/reducer";
import events from "./event/reducer";
import tracks from "./tracks/reducer";
import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

const createReducer = (asyncReducers) =>
  combineReducers({
    auth,
    responseHandler,
    eventsData: events,
    tracksData: tracks,
    router: routerReducer,
    ...asyncReducers,
  });

export default createReducer;
