import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { QRCodeSVG } from "qrcode.react";
import { Typography, styled } from "@mui/material";
import CustomButton from "components/CustomButton/CustomButton";
import { vars } from "utils/vars";

const StyledBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  height: 450,
  backgroundColor: "white",
  border: "1px solid #000",
  padding: theme.spacing(1),
  "& > p": {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: "center",
  },
  "& > div": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& > div > div": {
    display: "flex",
    justifyContent: "center",
  },
  "& > div > div > button": {
    width: "10%",
  },
  [theme.breakpoints.down("sm")]: {
    width: 390,
    height: 440,
  },
}));

const QrModal = (props) => {
  const { open, handleClose, events } = props;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <StyledBox sx={{ width: { xs: 340 }, height: { xs: 400 } }}>
          <Typography
            style={{
              color: "black",
              fontFamily: "MyriadCondensedRoman",
              fontSize: "18px",
            }}
          >
            Please scan the QR Code to view the event.
          </Typography>
          <Box my={3}>
            <QRCodeSVG
              size={220}
              value={`${vars?.domainUrl}/user/${events[0]?.userId}`}
            />
          </Box>
          <Box>
            <CustomButton handleClick={handleClose} btnName="Close" />
          </Box>
        </StyledBox>
      </Modal>
    </div>
  );
};
export default QrModal;
