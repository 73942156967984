let env = process.env;
export const vars = {
  backendUrl:
    env.NODE_ENV === "production"
      ? env.REACT_APP_BACKEND_URI_LIVE
      : env.REACT_APP_BACKEND_URI,
  domainUrl:
    env.NODE_ENV === "production"
      ? env.REACT_APP_DOMAIN_URL_LIVE
      : env.REACT_APP_BACKEND_URI,
};
