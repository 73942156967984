import { AppBar, Box, Grid, Toolbar, Typography, styled } from "@mui/material";
import React from "react";
import Logo from "assets/images/header/logo.svg";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import ProfileMenu from "components/ProfileMenu/ProfileMenu";

const NowPlaying = styled(Typography)(() => ({
  fontSize: "16px",
  lineHeight: "18px",
  margin: 0,
  fontFamily: "MyriadCondensedRoman",
  display: "flex",
  justifyContent: "flex-end",
}));

const StyledGrid = styled(Grid)(() => ({
  fontSize: "16px",
  lineHeight: "18px",
  margin: 0,
  padding: "20px 0 0px",
  "& > h6": {
    fontSize: "16px",
    lineHeight: "18px",
    fontFamily: "MyriadCondensedRoman",
  },
  "& > h3": {
    fontSize: "20px",
    lineHeight: "20px",
    fontFamily: "MyriadProBlackCondensed",
  },
  "& > div > h6": {
    fontSize: "16px",
    lineHeight: "18px",
    fontFamily: "MyriadCondensedRoman",
  },
  "& > div > div > h6": {
    fontSize: "16px",
    lineHeight: "18px",
    fontFamily: "MyriadCondensedRoman",
  },
  "& > div > div > h3": {
    fontSize: "20px",
    lineHeight: "20px",
    fontFamily: "MyriadProBlackCondensed",
  },
}));

let style = {
  top: "70px !important",
  right: "38px !important",
  left: "auto !important",
  maxWidth: "inherit",
  minWidth: "auto",
};

const Header = (props) => {
  const location = useLocation();
  const userFlag =
    location?.pathname?.match(/user/g)?.length > 0 &&
    location.pathname.match(/user/g)[0];
  const hostFlag =
    location?.pathname?.match(/host/g)?.length > 0 &&
    location?.pathname?.match(/host/g)[0];
  const { currentTrack, isLogin, token } = props;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{
          background: "linear-gradient(to bottom, #ef4136, #f27e5f)",
          position: (userFlag === "user" || hostFlag === "host") && {
            xs: "fixed",
            sm: "fixed",
            md: "fixed",
            lg: "relative",
          },
          zIndex: 99999,
        }}
        position="static"
        className="header"
      >
        <Toolbar>
          <Grid container display="flex" alignItems="center">
            <Grid
              item
              xs={12}
              sm={12}
              lg={token && isLogin ? 11 : 12}
              display="flex"
              justifyContent="center"
              sx={{ pl: { lg: token && isLogin ? 15.5 : 0 } }}
            >
              <img width={158} src={Logo} alt="logo" />
            </Grid>

            {!location.pathname.includes("user") &&
              !location.pathname.includes("host") && (
                <Grid
                  sx={{
                    display: {
                      lg: "flex",
                      md: "none",
                      sm: "none",
                      xs: "none",
                    },
                  }}
                  item
                  xs={12}
                  sm={12}
                  lg={1}
                  justifyContent="flex-end"
                >
                  <ProfileMenu styleData={style} />
                </Grid>
              )}
          </Grid>
        </Toolbar>

        {userFlag === "user" && (
          <Grid
            item
            sx={{
              display: { lg: "none", md: "none" },
            }}
            px={1}
          >
            <StyledGrid>
              {
                // votingFlag && timeLeft > 0 ? (
                //   <Grid container justifyContent="space-between">
                //     <Box>
                //       <Typography variant="h6">NOW PLAYING:</Typography>
                //       <Typography variant="h3">
                //         {currentTrack?.name.split("(")[0].trim().toUpperCase()}
                //       </Typography>
                //       <Typography variant="h6">
                //         {currentTrack?.item?.artists[0]?.name.toUpperCase()}
                //       </Typography>
                //     </Box>
                //     <Box>
                //       <NowPlaying
                //         style={{
                //           color: "black",
                //           textAlign: "right",
                //           lineHeight: "20px",
                //         }}
                //         component="h2"
                //         variant="p"
                //       >
                //         VOTING <br /> ENDS IN {timeLeft}
                //       </NowPlaying>
                //       <NowPlaying component="p" variant="p">
                //         UP NEXT:
                //       </NowPlaying>
                //     </Box>
                //   </Grid>
                // ) :
                currentTrack?.name || currentTrack?.artist ? (
                  <>
                    <Typography variant="h6">NOW PLAYING:</Typography>
                    <Typography variant="h3">
                      {currentTrack?.name.split("(")[0].trim().toUpperCase()}{" "}
                    </Typography>
                    <Grid container justifyContent="space-between">
                      <Typography variant="h6">
                        {currentTrack?.artistName &&
                          currentTrack?.artistName.toUpperCase()}
                      </Typography>
                      <NowPlaying component="p" variant="p">
                        UP NEXT:
                      </NowPlaying>
                    </Grid>
                  </>
                ) : (
                  <NowPlaying component="p" variant="p">
                    UP NEXT:
                  </NowPlaying>
                )
              }
            </StyledGrid>
          </Grid>
        )}
      </AppBar>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    currentTrack: state.tracksData.currentTrack,
    token: state.auth.token,
    isLogin: state.auth.isLogin,
    user: state.auth.user,
    timeLeft: state.tracksData.timeLeft,
    votingFlag: state.tracksData.votingFlag,
  };
};

export default compose(connect(mapStateToProps, null))(Header);
