import { Grid, Button, CircularProgress, styled } from "@mui/material";
import React from "react";

const CustomGrid = styled(Grid)(({ theme }) => ({
  padding: "15px 0px 15px 0px",
  "& > button": {
    borderRadius: "6px",
    justifyContent: "center",
    fontFamily: "MyriadCondensedRoman",
    alignItems: "center",
    padding: "10px",
    textDecoration: "none",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "16px",
    color: "#000",
    transition: " 0.2s",
    position: "relative",
    zIndex: 99,
    overflow: "hidden",
    border: "none",
    display: "block",
    letterSpacing: "1px",
  },
}));

const CustomButton = (props) => {
  const { btnName, isFetching, handleClick, flag } = props;
  return (
    <CustomGrid item xs={12} className="form-group">
      <Button
        type="submit"
        variant="contained"
        className="btn-common"
        fullWidth
        onClick={handleClick}
        disabled={flag}
        sx={{
          backgroundColor: "#a7a9ac",
          "&:hover": {
            backgroundColor: "#ef4136",
          },
          color: flag && "grey !important",
        }}
      >
        {isFetching ? (
          <CircularProgress
            sx={{
              color: "common.white",
              width: "25px !important",
              height: "25px !important",
              mr: (theme) => theme.spacing(2),
            }}
          />
        ) : (
          btnName
        )}
      </Button>
    </CustomGrid>
  );
};

export default CustomButton;
