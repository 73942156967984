const authActions = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  CHECK: "CHECK",

  login: (data) => {
    return {
      type: authActions.LOGIN,
      isLogin: true,
      token: data.token,
    };
  },
  check: (data) => {
    return {
      type: authActions.CHECK,
      user: data.user,
    };
  },

  logout: () => {
    window.localStorage.clear("");
    return {
      type: authActions.LOGOUT,
      isLogin: false,
      token: "",
      user: {},
    };
  },
};

export default authActions;
