import authAction from "./actions";
import Cookies from "js-cookie";

const initState = {
  isLogin: Cookies.get("isLogin") ? Cookies.get("isLogin") === "true" : false,
  token: Cookies.get("access_token") ? Cookies.get("access_token") : "",
  user: {},
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case authAction.LOGIN:
      return {
        ...state,
        isLogin: action.isLogin,
        token: action.token,
      };

    case authAction.CHECK:
      return {
        ...state,
        user: action.user,
      };

    case authAction.LOGOUT:
      return {
        ...state,
        isLogin: action.isLogin,
        token: action.token,
        user: action.user,
      };
    default:
      return state;
  }
}
