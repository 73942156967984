import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { routes } from "routes/routes";
import Layout from "layouts/Layout/Layout";
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "store/auth/actions";
import { Suspense } from "react";
import Loader from "components/Loader/Loader";
import useCheck from "hooks/useCheck";

const { check, logout } = AuthActions;

const App = (props) => {
  useCheck(props);
  const { token, isLogin } = props;
  const PrivateRoute = ({ isLogin, token }) =>
    isLogin && token ? <Outlet /> : <Navigate to="/auth" />;

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* Public Routes */}
        {routes
          .filter((route) => !route.isPrivate) // Filter out private routes
          .map((route, i) => (
            <Route
              key={`routes${i}`}
              exact
              path={route.path}
              element={
                route.path === "page-not-found" ? (
                  route.element
                ) : (
                  <Layout>{route.element}</Layout>
                )
              }
            />
          ))}
        {/* Public Routes */}

        {/* Protected Routes (accessible only when logged in) */}
        <Route element={<PrivateRoute isLogin={isLogin} token={token} />}>
          {routes
            .filter((route) => route.isPrivate) // Filter out private routes
            .map((route, i) => (
              <Route
                key={`routes${i}`}
                exact
                path={route.path}
                element={<Layout>{route.element}</Layout>}
              />
            ))}
        </Route>
        {/* Protected Routes (accessible only when logged in) */}

        <Route
          path="*" // Catch all unmatched routes
          element={<Navigate to="/page-not-found" />} // Redirect to login page
        />
      </Routes>
    </Suspense>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    isLogin: state.auth.isLogin,
  };
};

export default compose(connect(mapStateToProps, { check, logout }))(App);
