// import Login from "pages/Auth/Login/Login";
// import Register from "pages/Auth/Register/Register";
// import Dashboard from "pages/Dashboard/Dashboard";
// import Profile from "pages/Profile/Profile";
// import Event from "pages/Event/Event";
// import TermsAndConditions from "pages/TermsAndConditions/TermsAndConditions";
// import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
// import HowItWorks from "pages/HowItWorks/HowItWorks";
// import UserPlaylist from "pages/UserPlaylist/UserPlaylist";
// import HostPlaylist from "pages/HostPlaylist/HostPlaylist";
// import ChangePassword from "pages/ChangePassword/ChangePassword";
// import UpdatePassword from "pages/Auth/UpdatePassword/UpdatePassword";
// import NotFound from "pages/404/NotFound";
import { lazy } from "react";

const Auth = lazy(() => import("pages/Auth/index"));
const Login = lazy(() => import("pages/Auth/Login/Login"));
const Register = lazy(() => import("pages/Auth/Register/Register"));
const Dashboard = lazy(() => import("pages/Dashboard/Dashboard"));
const Profile = lazy(() => import("pages/Profile/Profile"));
const Event = lazy(() => import("pages/Event/Event"));
const TermsAndConditions = lazy(() =>
  import("pages/TermsAndConditions/TermsAndConditions")
);
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy/PrivacyPolicy"));
const HowItWorks = lazy(() => import("pages/HowItWorks/HowItWorks"));
const UserPlaylist = lazy(() => import("pages/UserPlaylist/UserPlaylist"));
const HostPlaylist = lazy(() => import("pages/HostPlaylist/HostPlaylist"));
const ChangePassword = lazy(() =>
  import("pages/ChangePassword/ChangePassword")
);
const UpdatePassword = lazy(() =>
  import("pages/Auth/UpdatePassword/UpdatePassword")
);
const ForgotPassword = lazy(() =>
  import("pages/Auth/ForgotPassword/ForgotPassword")
);
const NotFound = lazy(() => import("pages/404/NotFound"));

export const routes = [
  { path: "/", element: <Dashboard />, isPrivate: true },
  { path: "/auth", element: <Auth />, isPrivate: false },
  { path: "/login", element: <Login />, isPrivate: false },
  { path: "/register", element: <Register />, isPrivate: false },
  { path: "/forgot-password", element: <ForgotPassword />, isPrivate: false },
  {
    path: "/update-password/:token",
    element: <UpdatePassword />,
    isPrivate: false,
  },
  { path: "/how-it-works", element: <HowItWorks />, isPrivate: false },
  { path: "/change-password", element: <ChangePassword />, isPrivate: true },
  { path: "/profile/:code?", element: <Profile />, isPrivate: true },
  { path: "/event", element: <Event />, isPrivate: true },
  { path: "/event/:id", element: <Event />, isPrivate: true },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
    isPrivate: true,
  },
  { path: "/privacy-policy", element: <PrivacyPolicy />, isPrivate: true },
  {
    path: "/user/:id",
    element: <UserPlaylist />,
    isPrivate: false,
  },
  {
    path: "/host/:id",
    element: <HostPlaylist />,
    isPrivate: false,
  },
  {
    path: "page-not-found",
    element: <NotFound />,
    isPrivate: false,
  },
];
