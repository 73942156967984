import axios from "axios";
import { vars } from "utils/vars";

const BACKEND_URI = vars.backendUrl ? vars.backendUrl : "";

export const api = (token) => {
  if (typeof token === "string" && token.split(".").length === 3)
    return axios.create({
      withCredentials: true,
      baseURL: `${BACKEND_URI}/api/v1/`,
      headers: { Authorization: token },
    });
  else
    return axios.create({
      withCredentials: true,
      baseURL: `${BACKEND_URI}/api/v1/`,
    });
};

export const handleResponse = (res) => {
  try {
    const data = res?.data;
    if (data?.error) {
      const error = data.message ? data.message : data.error;
      return Promise.reject(error);
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const handleError = (err) => {
  if (err?.response?.status === 401) {
    document.cookie = document.cookie = `token= ;SameSite=strict;max-age=0}`;
    if (window.location.pathname !== "/login") {
      window.location = "/login";
    }
  }
  return err.response.data;
};
