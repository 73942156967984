import { Grid } from "@mui/material";
import React from "react";
import BeatLoader from "react-spinners/ClipLoader";

const override = {
  border: "3px solid",
};

const Loader = () => {
  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <BeatLoader color="#ef4136" cssOverride={override} size={50} />
    </Grid>
  );
};

export default Loader;
