import { loginApi, registerApi, forgotPasswordApi, logoutApi } from "api/auth";
import { updatePasswordApi } from "api/user";
import { useNavigate, useParams } from "react-router-dom";

const useAuth = (props) => {
  const {
    fetching,
    login,
    error,
    isFetching,
    values,
    handleSubmit,
    isValid,
    token,
    isLogin,
    logout,
  } = props;
  const navigate = useNavigate();
  const params = useParams();

  const handleLogin = async (e) => {
    e.preventDefault();
    handleSubmit();

    if (isValid && !isFetching) {
      fetching(true);
      try {
        const response = await loginApi(values);
        if (response.success) {
          navigate("/");
          login(response.data);
          // success(response.message);
          fetching(false);
        } else {
          error(response.message);
          fetching(false);
        }
      } catch (error) {
        console.log(error);
        fetching(false);
      }
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    handleSubmit();
    if (isValid && !isFetching) {
      try {
        const response = await registerApi(values);
        if (response?.success) {
          // success(response.message);
          navigate("/login");
          fetching(false);
        } else {
          error(response.message);
          fetching(false);
        }
      } catch (error) {
        fetching(false);
      }
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    if (isValid && !isFetching) {
      fetching(true);
      try {
        const response = await forgotPasswordApi(values);
        if (response?.success) {
          // success(response.message);
          fetching(false);
        } else {
          error(response.message);
          fetching(false);
        }
      } catch (error) {
        console.log(error);
        fetching(false);
      }
    }
  };

  const handleLogout = async () => {
    if (token && isLogin) {
      try {
        const response = await logoutApi();
        if (response.success) {
          logout();
          navigate("/auth");
        } else {
          error(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleUpdatePassword = async (e) => {
    try {
      e.preventDefault();

      if (isValid && !isFetching) {
        fetching(true);
        try {
          const response = await updatePasswordApi(params.token, values);
          if (response?.success) {
            navigate("/login");
            fetching(false);
          } else {
            error(response.message);
            fetching(false);
          }
        } catch (error) {
          console.log(error);
          fetching(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    handleForgotPassword,
    handleUpdatePassword,
    handleRegister,
    handleLogout,
    handleLogin,
  };
};

export default useAuth;
