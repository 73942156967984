import React, { useState } from "react";
import User from "assets/images/header/user.png";
import { Box, Grid, IconButton, Menu, MenuItem, styled } from "@mui/material";
import { connect } from "react-redux";
import { compose } from "redux";
import useAuth from "hooks/useAuth";
import AuthActions from "store/auth/actions";
import { useNavigate } from "react-router-dom";
import QrModal from "components/Modals/QrModal";

const { logout } = AuthActions;

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
  "& > p": {
    // Selects adjacent sibling elements
    marginLeft: theme.spacing(1), // Adjust margin as needed
  },
}));

const ProfileMenu = (props) => {
  const navigate = useNavigate();
  const { token, isLogin, flag, styleData, events } = props;
  const { handleLogout } = useAuth(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    navigate("/profile");
    handleClose();
  };

  const handleChangePassword = () => {
    navigate("/change-password");
    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
    handleClose();
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    token &&
    isLogin && (
      <Grid sx={{ display: flag === "web" && { lg: "none" } }}>
        <StyledIconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <img width={40} src={User} alt="user" />
        </StyledIconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{ "& .MuiMenu-paper": styleData }}
        >
          <MenuItem onClick={handleProfile}>Profile</MenuItem>
          <MenuItem onClick={handleOpen}>QR Code</MenuItem>
          <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>

        <Box sx={{ flexGrow: 1 }}>
          <QrModal handleClose={handleCloseModal} open={open} events={events} />
        </Box>
      </Grid>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    isLogin: state.auth.isLogin,
    events: state.eventsData.events,
  };
};

export default compose(connect(mapStateToProps, { logout }))(ProfileMenu);
