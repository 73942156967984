import { api, handleResponse, handleError } from "./apiService";

export const updateProfileApi = (token, data) =>
  api(token)
    .put("/user/update-profile", data)
    .then(handleResponse)
    .catch(handleError);

export const changePasswordApi = (token, data) =>
  api(token)
    .put("/user/change-password", data)
    .then(handleResponse)
    .catch(handleError);

export const updatePasswordApi = (token, data) =>
  api(token)
    .post("/user/update-password", data)
    .then(handleResponse)
    .catch(handleError);

export const updateSpotifySettingsApi = (token, data) =>
  api(token)
    .put("/user/update-spotify-settings", data)
    .then(handleResponse)
    .catch(handleError);

export const updateGenresSettingsApi = (token, data) =>
  api(token)
    .put("/user/update-genres-settings", data)
    .then(handleResponse)
    .catch(handleError);
