import TrackActions from "./actions";

const initState = {
  tracks: [],
  nextTrackIndex: 0,
  currentTrack: null,
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case TrackActions.TRACKS:
      return {
        ...state,
        tracks: action.tracks,
      };

    case TrackActions.START_TRACK:
      return {
        ...state,
        start: action.start,
      };

    case TrackActions.VOTING_TIMER_DATA:
      return {
        ...state,
        votingTimer: action.votingTimer,
        votingFlag: action.votingFlag,
      };

    case TrackActions.SET_TIMER:
      return {
        ...state,
        timeLeft: action.timeLeft,
      };

    case TrackActions.CURRENT_TRACK:
      return {
        ...state,
        currentTrack: action.currentTrack,
      };

    case TrackActions.NEXT_TRACK:
      return {
        ...state,
        nextTrackIndex: action.nextTrackIndex,
      };

    default:
      return state;
  }
}
