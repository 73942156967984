const TrackActions = {
  TRACKS: "TRACKS",
  START_TRACK: "START_TRACK",
  CURRENT_TRACK: "CURRENT_TRACK",
  VOTING_TIMER_DATA: "VOTING_TIMER_DATA",
  SET_TIMER: "SET_TIMER",
  NEXT_TRACK: "NEXT_TRACK",

  data: (data) => {
    return {
      type: TrackActions.TRACKS,
      tracks: data,
    };
  },
  startData: (start) => {
    return {
      type: TrackActions.START_TRACK,
      start: start,
    };
  },
  voteTimerData: (data) => {
    return {
      type: TrackActions.VOTING_TIMER_DATA,
      votingTimer: data?.votingTimer,
      votingFlag: data?.votingFlag,
    };
  },
  setTimer: (seconds) => {
    return {
      type: TrackActions.SET_TIMER,
      timeLeft: seconds,
    };
  },
  currentTrackData: (data) => {
    return {
      type: TrackActions.CURRENT_TRACK,
      currentTrack: data,
    };
  },
  nextTrackData: (index) => {
    return {
      type: TrackActions.NEXT_TRACK,
      nextTrackIndex: index,
    };
  },
};

export default TrackActions;
