import EventActions from "./actions";

const initState = {
  events: [],
  eventId: null,
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case EventActions.EVENTS:
      return {
        ...state,
        events: action.events,
      };

    case EventActions.SET_EVENT_ID:
      return {
        ...state,
        eventId: action.eventId,
      };

    default:
      return state;
  }
}
