import { api, handleResponse, handleError } from "./apiService";

export const registerApi = (data) =>
  api().post("/auth/register", data).then(handleResponse).catch(handleError);

export const loginApi = (data) =>
  api().post("/auth/login", data).then(handleResponse).catch(handleError);

export const checkUserApi = (token) =>
  api(token).get("/auth/check").then(handleResponse).catch(handleError);

export const logoutApi = (token) =>
  api(token).get("/auth/logout").then(handleResponse).catch(handleError);

export const forgotPasswordApi = (data) =>
  api()
    .post("/auth/forgot-password", data)
    .then(handleResponse)
    .catch(handleError);
