import { checkUserApi } from "api/auth";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useCheck = (props) => {
  const location = useLocation();
  const { token, logout, check } = props;

  const userFlag = getFlag(/user/g);
  const hostFlag = getFlag(/host/g);

  function getFlag(string) {
    return (
      location?.pathname?.match(string)?.length > 0 &&
      location?.pathname?.match(string)[0]
    );
  }

  const checkUser = async () => {
    try {
      const response = await checkUserApi(token);

      if (response?.success) {
        let data = {
          token,
          user: response?.data?.userData,
        };
        props?.check && check(data);
      }

      if (response.message.name === "TokenExpiredError") {
        props.logout && logout();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userFlag !== "user" && hostFlag !== "host") {
      checkUser();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  return { checkUser };
};

export default useCheck;
