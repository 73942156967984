const EventActions = {
  EVENTS: "EVENTS",
  SET_EVENT_ID: "SET_EVENT_ID",

  eventData: (data) => {
    return {
      type: EventActions.EVENTS,
      events: data,
    };
  },

  setEventId: (eventId) => {
    return {
      type: EventActions.SET_EVENT_ID,
      eventId: eventId,
    };
  },
};

export default EventActions;
